export const color = {
    primary: '#00521E',
    primaryLight: '#b3cbbc',
    secondary: '#CED925',

    gold: '#d99e08',
    success: '#28a745',
    info: '#0570E7',
    gray: '#F2F2F4',
    white: '#FFFFFF',
    black: '#000000',
    danger: '#e74c3c',
    dark: '#424242',
    warning: '#ffc107',

    grayeTain: '#DCDCDC',
    grayLight: '#D3D3D3',
    grayArgent: '#C0C0C0',
    grayDark: '#A9A9A9',
}